// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");

function show_email_overlay() {
	$('body').addClass('is-email-overlay-open');
}
function show_info_overlay() {
	$('body').addClass('is-info-overlay-open');
}

$(document).ready(function ()
{
	$('#home-slider').slick({
		adaptiveHeight: false,
		arrows: false,
		autoPlay: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
		dots: true
	});

	$('#quote-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
		dots: false
	});


	$('#open-nav').click(function(){
		$('body').addClass('is-nav-overlay-open');
	});



	/*
	 *	REMEMBER ALERT CLOSE
	 */

	$('#site-alert .close').click( function(e){
		e.preventDefault();
		Cookies.set('site-alert', 'closed');
	});

	if (Cookies.get('site-alert') !== 'closed') {
        $('#site-alert').show();
    }



	/*
	 *	EMAIL OVERLAY
	 */

	$('#close-email-overlay').click(function(){
		$('body').removeClass('is-email-overlay-open');
	});

	$('#close-info-overlay').click(function(){
		$('body').removeClass('is-info-overlay-open');
	});
	
	$('#close-nav-overlay').click(function(){
		$('body').removeClass('is-nav-overlay-open');
	});




	/*
	 *	RANGE CONTROLS
	 */

	$( "#subscribe-range-slider" ).slider({
		range: true,
		min: 1990,
		max: 2020,
		values: [ 2000, 2020 ],
		slide: function( event, ui ) {
			$( "#subscribe-range-number-1" ).html( $("#subscribe-range-slider").slider("values", 0));
			$( "#subscribe-range-input-1" ).val( $("#subscribe-range-slider").slider("values", 0));
			$( "#subscribe-range-number-2" ).html( $("#subscribe-range-slider").slider("values", 1));
			$( "#subscribe-range-input-2" ).val( $("#subscribe-range-slider").slider("values", 1));
		}
	});
	$( "#subscribe-range-number-1" ).html( $("#subscribe-range-slider").slider("values", 0));
	$( "#subscribe-range-input-1" ).val( $("#subscribe-range-slider").slider("values", 0));
	$( "#subscribe-range-number-2" ).html( $("#subscribe-range-slider").slider("values", 1));
	$( "#subscribe-range-input-2" ).val( $("#subscribe-range-slider").slider("values", 1));

	
	$( "#budget-range-slider" ).slider({
		range: true,
		min: 0,
		max: 300000,
		values: [ 0, 200000 ],
		slide: function( event, ui ) {
			$( "#budget-range-number-1" ).html( '&euro; '+$("#budget-range-slider").slider("values", 0));
			$( "#budget-range-input-1" ).val( $("#budget-range-slider").slider("values", 0));
			$( "#budget-range-number-2" ).html( '&euro; '+$("#budget-range-slider").slider("values", 1));
			$( "#budget-range-input-2" ).val( $("#budget-range-slider").slider("values", 1));
		}
	});
	$( "#budget-range-number-1" ).html( '&euro; '+$("#budget-range-slider").slider("values", 0));
	$( "#budget-range-input-1" ).val( $("#budget-range-slider").slider("values", 0));
	$( "#budget-range-number-2" ).html( '&euro; '+$("#budget-range-slider").slider("values", 1));
	$( "#budget-range-input-2" ).val( $("#budget-range-slider").slider("values", 1));


	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});
	
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// File upload
	bsCustomFileInput.init()
});

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();
});



// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}
